

  .footer-container {

    justify-content: center;
    align-items: center;
    background-color: #f5fafd;
    padding: 1rem 0;
    width: 100%;
  }

  .text-white a,
  .text-white a:hover,
  .text-white a:focus {
    color: #ffffff;
  }
  
  .p-footer {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #2e7d32!important;
  }
  
  .p-footer:not(:last-child) {
    margin-right: 1rem;
  }

