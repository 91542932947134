/* styles/About.css */

.About {
  background-color: #90aba5;
  padding: 3rem 0;
  color: #333;
}

.container {
  max-width: 100%;
  padding: 0 1rem; /* Add some padding for small screens */
}

h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  color: #ffffff !important;
}

.about-p {
  font-size: 1rem;
  line-height: 1.6;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  color: #ffffff !important;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0 30px; /* Use larger padding for larger screens */
  }
}
