/* styles/Header.css */

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #6d5580;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.navbar-brand, .nav-icon {
  display: flex;
  align-items: center;
}

.navbar-brand img, .nav-icon img {
  width: 100px; /* smaller logo and icon for all screens, to ensure they fit on smaller screens */
  height: auto;
}

.me-auto {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
}

.nav-link {
  font-family: 'Roboto', sans-serif;
  color: #ffffff !important;
}

.nav-link:hover {
  color: #ddd;
}

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .navbar {
    padding: 10px; /* less padding on smaller screens */
  }

  .navbar-brand img, .nav-icon img {
    width: 80px; /* even smaller logo and icon on smaller screens */
  }
}
