/* styles/Values.css */

.valueheader {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    color: #000000a8 !important;
  }
  


  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 20px;
  }
  
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-left: 15px;
    padding-right: 15px;
    border: none; 
  }
  
  .card {
    display: flex;
    flex-direction: column;
    border: none;
  }
  
  .card-img-top {
    width: 100%;
    height: auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: none; 
    padding: 20px;
  }
  
  .card-title {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    color: #000000a8 !important;
  }

  .card-text{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    color: #000000a8 !important;
  }
  
 
  
  .py-5 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important;
  }
  