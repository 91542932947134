
/* styles/Home.css */

.Home {
  width: 100%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .Home {
    padding: 10px;
  }
}
