/* styles/Services.css */

.Services {
    background-color: #f9f9f9; /* Change this to your preferred background color */
    padding: 3rem 0;
    color: #333; /* Change this to your preferred text color */
  }
  
  .h2-services {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    color: #222 !important; /* Change this to your preferred color */
  }
  
  .p-services {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    color: #222 !important; /* Change this to your preferred color */
  }
  