/* styles/Contact.css */

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
}

.contact-info{
  font-family: 'Roboto', sans-serif;
  color: #000000!important;
}

.contact-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact-container p {
  font-size: 1rem;
  line-height: 1.5;
}
