/* styles/App.css */

.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal scroll */
}

/* Optional: If you want the app to take the full height of the viewport */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
