/* styles/main.css */

body {
    font-family: 'Georgia', serif;
    line-height: 1.6;
    color: #333;
    padding: 1rem;
    background-color: #f5f5f0;
  }

  
  a {
    color: #1e90ff;
    text-decoration: none;
  }
  
  a:hover {
    color: #1c75bc;
  }
  
  .blog-post {
    max-width: 800px;
    margin: 0 auto;
  }
  
  pre {
    background-color: #e8e8e2;
    padding: 1rem;
    overflow-x: auto;
    margin-bottom: 1.5rem;
  }
  
  code {
    font-family: 'Courier New', Courier, monospace;
    background-color: #e8e8e2;
    padding: 3px;
  }
  
  blockquote {
    border-left: 5px solid #ccc;
    padding-left: 1rem;
    margin-bottom: 1.5rem;
    font-style: italic;
  }
  
  ul,
  ol {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
  }
  
  li {
    margin-bottom: 0.5rem;
  }
  
  .react-markdown h1,
  .react-markdown h2,
  .react-markdown h3,
  .react-markdown h4,
  .react-markdown h5,
  .react-markdown h6 {
    margin-bottom: 1rem;
    color: #333 !important;
  }
  
  .react-markdown p {
    margin-bottom: 1.5rem;
    text-align: justify;
    color: #333 !important;
  }
  
  .react-markdown a {
    text-decoration: none;
    color: #333 !important;
  }
  
  .react-markdown a:hover {
    color: #333 !important;
  }
  
  .react-markdown ul,
  .react-markdown ol {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
  }
  
  .react-markdown li {
    margin-bottom: 0.5rem;
  }
  