/* styles/About.css */

.BottomSection {
    background-color: #ceb8df;
    padding: 3rem 0;
    color: #333;
  }
  
  .container {
    max-width: 100%;
    padding: 0 1rem; /* Add some padding for small screens */
  }
  
  .my-header {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: right; /* align text to the left */
    font-family: 'Roboto', sans-serif;
    color: #ffffff !important;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    color: #ffffff !important;
  }
  
  @media screen and (min-width: 768px) {
    .container {
      padding: 0 30px; /* Use larger padding for larger screens */
    }
  }
  
  .icon-text {
    display: flex;
    align-items: center;
  }
  
  .my-icon {
    margin-right: 20px;
  }
  